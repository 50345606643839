'use strict';

angular.module('enervexSalesappApp').factory('StackOption', function ($resource) {
	return $resource('/api/stack-options/:id', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		}
	});
});
